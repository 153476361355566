import React from 'react';
import '../../styles/InvalidPage.css';
import { invalidIcon } from '../../assets';

const InvalidPage = () => {
  // const navigate = useNavigate();

  return (
    <div className="invalid-page-container">
      <img src={invalidIcon} alt="Error Icon" className="error-icon" />
      <h1 className="invalid-page-title">404 - Page Not Found</h1>
      <p className="invalid-page-message">
        The page you are looking for doesn't exist or has been moved.
      </p>
      {/* <button className="go-back-button" onClick={() => navigate('/')}>
        Go Back Home
      </button> */}
    </div>
  );
};

export default InvalidPage;
