import { Outlet, useLocation } from "react-router-dom";
import Login from "../pages/Login";

function RootLayout() {
  const location = useLocation();
  const home = location.pathname === "/";

  return (
    <>
      {home && <Login />}
      <Outlet />
    </>
  );
}

export default RootLayout;
