import React from 'react';
import { toast as showToast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Toast = ({ message, type = 'info', position = 'top-right', autoClose = 5000 }) => {
  const showToastMessage = () => {
    switch (type) {
      case 'success':
        showToast.success(message, {
          position,
          autoClose,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        break;
      case 'error':
        showToast.error(message, {
          position,
          autoClose,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        break;
      default:
        showToast.info(message, {
          position,
          autoClose,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        break;
    }
  };

  return (
    <>
      <ToastContainer />
      {showToastMessage()}
    </>
  );
};

export default Toast;
