import React from 'react';
import { Modal, Button } from 'antd';

const ConfirmationModal = ({ isOpen, onConfirm, onCancel, loading, title, message }) => {
  return (
    <Modal
      title={title}
      centered
      style={{zIndex: 10001}}
      open={isOpen}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" className="create-modal-cancel-button" onClick={onCancel}>
          No
        </Button>,
        <Button
          key="confirm"
          className="create-modal-confirm-button"
          onClick={onConfirm}
          loading={loading}
          disabled={loading}
        >
          Yes
        </Button>,
      ]}
      width={500}
    >
      <p>{message}</p>
    </Modal>
  );
};

export default ConfirmationModal;
