import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function RouterError() {
  const navigate = useNavigate();

  useEffect(() => {
    const timeout = setTimeout(() => {
      navigate('/login');
    }, 1000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <div>
      <h1>Route Error</h1>
      <p>Redirecting to recruiters...</p>
    </div>
  );
}

export default RouterError;