import React from 'react';
import '../../styles/SuccessPage.css';
import { checkIcon } from '../../assets';

const SuccessPage = () => {
  // const navigate = useNavigate();

  return (
    <div className="success-page-container">
      <img src={checkIcon} alt="Success Icon" className="error-icon" />
      <h1 className="success-page-title">Success!</h1>
      <p className="success-page-message">
        Your details has been successfully updated...
      </p>
      {/* <button className="go-back-button" onClick={() => navigate('/')}>
        Go Back Home
      </button> */}
    </div>
  );
};

export default SuccessPage;
