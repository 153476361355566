import React from "react";

const StatusBadge = ({ status }) => {
  const activeStyle = {
    borderRadius: "16px",
    padding: "2px 8px",
    width: "53px",
    height: "18px",
    backgroundColor: "#ECFDF3",
    color: "#027A48",
    fontWeight: 500,
    fontSize: "12px",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    lineHeight: "14px",
  };

  const inactiveStyle = {
    borderRadius: "16px",
    padding: "2px 8px",
    width: "53px",
    height: "18px",
    backgroundColor: "#FEF3F2",
    color: "#B42318",
    fontWeight: 500,
    fontSize: "12px",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    lineHeight: "14px",
  };

  return (
    <div style={status === "Active" ? activeStyle : inactiveStyle}>
      {status === "Active" ? "Active" : "Inactive"}
    </div>
  );
};

export default StatusBadge;
