import { Checkbox, Input, Modal } from "antd";
import React from "react";
import { searchIcon } from "../assets";

const CandidateLocationsModal = ({ isModalOpen, handleOk }) => {
  const onCheckbox = (e) => {
    console.log(`checked = ${e.target.checked}`);
  };
  return (
    <Modal
      title="Locations"
      centered
      open={isModalOpen}
      onOk={handleOk}
      okText="Apply"
      className="locations-modal"
      width={638}
    onCancel={handleOk}
    >
      <Input
        size="large"
        placeholder="Search Location"
        prefix={<img src={searchIcon} alt="" style={{ marginRight: "5px" }} />}
        style={{ width: "183px", marginBottom: "16px" }}
      />

      <div className="checkbox-list">
        <Checkbox onChange={onCheckbox}>
          Bengaluru <small>(3469)</small>
        </Checkbox>
        <Checkbox onChange={onCheckbox}>
          Hosur <small>(8)</small>
        </Checkbox>
        <Checkbox onChange={onCheckbox}>
          Hyderabad <small>(1200)</small>
        </Checkbox>
        <Checkbox onChange={onCheckbox}>
          Pune <small>(4000)</small>
        </Checkbox>
        <Checkbox onChange={onCheckbox}>
          Bengaluru <small>(3469)</small>
        </Checkbox>
        <Checkbox onChange={onCheckbox}>
          Hosur <small>(8)</small>
        </Checkbox>
        <Checkbox onChange={onCheckbox}>
          Hyderabad <small>(1200)</small>
        </Checkbox>
        <Checkbox onChange={onCheckbox}>
          Pune <small>(4000)</small>
        </Checkbox>
        <Checkbox onChange={onCheckbox}>
          Bengaluru <small>(3469)</small>
        </Checkbox>
        <Checkbox onChange={onCheckbox}>
          Hosur <small>(8)</small>
        </Checkbox>
        <Checkbox onChange={onCheckbox}>
          Hyderabad <small>(1200)</small>
        </Checkbox>
        <Checkbox onChange={onCheckbox}>
          Pune <small>(4000)</small>
        </Checkbox>
        <Checkbox onChange={onCheckbox}>
          Bengaluru <small>(3469)</small>
        </Checkbox>
        <Checkbox onChange={onCheckbox}>
          Hosur <small>(8)</small>
        </Checkbox>
        <Checkbox onChange={onCheckbox}>
          Hyderabad <small>(1200)</small>
        </Checkbox>
        <Checkbox onChange={onCheckbox}>
          Pune <small>(4000)</small>
        </Checkbox>
        <Checkbox onChange={onCheckbox}>
          Bengaluru <small>(3469)</small>
        </Checkbox>
        <Checkbox onChange={onCheckbox}>
          Hosur <small>(8)</small>
        </Checkbox>
        <Checkbox onChange={onCheckbox}>
          Hyderabad <small>(1200)</small>
        </Checkbox>
        <Checkbox onChange={onCheckbox}>
          Pune <small>(4000)</small>
        </Checkbox>
      </div>
    </Modal>
  );
};

export default CandidateLocationsModal;
