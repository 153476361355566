import React, { useState } from "react";
import { Modal, Button, Upload } from "antd";
import { uploadFileIcon } from "../../assets";
import "../../styles/ImportFileModal.css";
import Toast from "../Toast/Toast";
import { post } from "../../lib/Api";
const baseUrl = process.env.REACT_APP_BACKEND_URL;

const ImportZipModal = ({
  isVisible,
  onClose,
  onImport,
//   downloadSampleSheetUrl,
  title = "Import File",
  width = 800,
  accept = ".zip",
}) => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleFileChange = ({ file }) => {
    setFile(file);
  };

  const handleImport = () => {
    if (file) {
      setLoading(true);
      onImport(file);
      setLoading(false);
      onClose();
      setFile(null);
    }
  };

//   const downloadSampleSheet = async () => {
//     setLoading(true);
//     try {
//     const res = await post(downloadSampleSheetUrl, "", {}, true);
//       if (res?.status === true) {
//         const element = document.createElement("a");
//         element.href = baseUrl + res?.file_path;
//         element.download = "";
//         element.click();
//         Toast({ message: "Report successfully downloaded", type: "success" });
//       } else {
//         Toast({ message: res?.message, type: "error" });
//       }
//       setLoading(false);
//     } catch (error) {
//       setLoading(false);
//       Toast({
//         message: "Network Error",
//         type: "error",
//       });
//     }
//   };

  return (
    <Modal
      title={<div className="modal-title">{title}</div>}
      open={isVisible}
      centered
      width={width}
      onCancel={onClose}
      footer={[
        <Button key="cancel" className="cancel-button" onClick={onClose}>
          Cancel
        </Button>,
        <Button
          key="import"
          type="primary"
          className="import-button"
          onClick={handleImport}
          loading={loading}
          disabled={!file}
        >
          {loading ? "Importing..." : "Import"}
        </Button>,
      ]}
    >
      <Upload
        beforeUpload={() => false}
        onChange={handleFileChange}
        accept={accept}
      >
        <div className="upload-content">
          <div>
            <img
              src={uploadFileIcon}
              alt="Upload Icon"
              width={100}
              height={100}
            />
          </div>
          <div style={{ fontWeight: 500, fontSize: "30px" }}>
            Upload your file
          </div>
          <div style={{ fontWeight: 500, fontSize: "15px" }}>
            (Only zip format)
          </div>
        </div>
      </Upload>
      {/* <div
        className="download-sample-sheet-button"
        onClick={downloadSampleSheet}
      >
        Download Sample Sheet
      </div> */}
    </Modal>
  );
};

export default ImportZipModal;
