import React from 'react';
import "../../styles/Login.css";
// import "../../";
import PropTypes from 'prop-types';

const LoginSlide = ({
  image,
  heading,
  description,
  loadingDivCount,
  activeCircleIndex,
}) => {
  const circles = Array.from({ length: loadingDivCount }, (_, index) => (
    <div
      key={index}
      className={index+1 === activeCircleIndex ? 'active-circle' : 'circle'}
    ></div>
  ));

  return (
    <div className="right-panel" style={{ border: "1px solid #dae1e7" }}>
      <img src={image} alt="Secure Login" className="right-panel-img" />
      <div className="slide-heading">{heading}</div>
      <div className="slide-description">{description}</div>
      <div className="loading-circle">{circles}</div>
    </div>
  );
};

LoginSlide.propTypes = {
  image: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  loadingDivCount: PropTypes.number.isRequired,
  activeCircleIndex: PropTypes.number.isRequired,
};

export default LoginSlide;
